// This file generates classes based on the $variants and applies support for them for all $components
// The classes are used as a replacement for the color attribute, which is not compatible with Angular m3
// example: primary-color, secondary-color, error-color etc.

@use '@angular/material' as mat;
@use "sass:meta";

$variants: 'primary', 'secondary', 'error';
$components: (
  'badge',
  'button',
  'checkbox',
  'chips',
  'datepicker',
  'form-field',
  'fab',
  'icon',
  'option',
  'progress-bar',
  'progress-spinner',
  'pseudo-checkbox',
  'radio',
  'select',
  'slide-toggle',
  'slider',
  'stepper',
  'tabs'
);

@mixin mat-component-variants($theme) {
  @each $variant in $variants {
    .#{$variant}-color {
      @each $component in $components {
        @if (is-variant-supported($variant, $component)) {
          @include meta.apply(meta.get-mixin("#{$component}-color", mat), $theme, $color-variant: $variant);
        }
      }
    }
    //generates specific component classes, doesn't seem necessary right now
    // @each $component in $components {
    //   @if (is-variant-supported($variant, $component)) {
    //     .#{$variant}-#{$component} {
    //       @include meta.apply(meta.get-mixin("#{$component}-color", mat), $theme, $color-variant: $variant);
    //     }
    //   }
    // }
  }
}

@function is-variant-supported($variant, $component) {
  @if ($variant == 'error' and $component == 'fab') {
    @return false;
  }

  @return true;
}