@import '~@angular/material/theming';
@import 'theme-helper';

@include mat-core();
@include angular-material-theme($dynamic-app-theme);
@include angular-material-typography($dynamic-typography);

body {
  margin: 0;
}
