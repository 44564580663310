@import '../../../styling/theme.scss';

// GLOBAL VARS
:root {
  --backgroundImage: url('https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fplusbus%2Fplusbus-login.svg?alt=media&token=65bb091d-3437-4e71-a3ea-7dceab0937da');
  --planningHeader: url('https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fplusbus%2Fplusbus-banner.png?alt=media&token=bd20d7a8-2915-4f5e-a058-780746eac92e');
  --primary: #e84a1e;
  --primary-contrast: white;
  --accent: #303440;
  --accent-contrast: white;
  --logo: url('https://firebasestorage.googleapis.com/v0/b/wemaron-buurtvervoer.appspot.com/o/organisations%2Fplusbus%2Fplusbus-logo-transparent.png?alt=media&token=7d8330c7-2141-458f-b16f-677261badd57');
}

.side-nav-top {
  .header-logo {
    .logo {
      width: 80px !important;
    }
  }
}
