/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import 'theme.scss';
@import '../../node_modules/angular-calendar/css/angular-calendar.css';

html,
body {
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
}

a {
  color: var(--primary);
}

.primary-bg {
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important;
}

.accent-bg {
  background-color: var(--accent) !important;
  color: var(--accent-contrast) !important;
}

.mat-dialog-title {
  margin: -24px -24px 0 -24px !important; // Needed for draggable dialogs
  padding: 24px 24px 20px 24px !important; // Needed for draggable dialogs
}

.avatar-placeholder {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  user-select: none;
}

// .title, .subtitle, .horizontal-menu, mat-card-title {
//   font-family: Raleway, "Helvetica Neue", sans-serif;
// }

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

//All Angular-Calender CSS

.cal-event {
  cursor: pointer !important;
}

.text-color-white .cal-event {
  color: #efefef !important;
}

.text-color-black .cal-event {
  color: #333333 !important;
}

.cal-week-view,
.cal-day-view {
  height: inherit !important;
}

.cal-draggable {
  cursor: default !important;
}

.cal-week-view .cal-time-events .cal-event {
  line-height: inherit !important;
}

.cal-week-view .cal-time-events .cal-day-columns {
  height: 100% !important;
}

.cal-week-view .cal-event {
  font-weight: 500;
}

.cal-week-view .cal-day-headers,
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: solid 1px rgba(0, 0, 0, 0) !important;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: solid 1px rgba(0, 0, 0, 0) !important;
}

.week-view .cal-week-view .cal-day-column {
  border-left: solid 2px rgb(157, 157, 157) !important;
}

.cal-week-view .cal-event-title {
  font-size: 12px;
  position: relative;
}

.cal-tooltip-inner {
  color: #fff !important;
}

.cal-week-view .cal-day-headers {
  border-top: solid 0px !important;
  border-right: solid 0px !important;
  margin-left: -1px;
  padding-right: 6.7px;
  border-bottom: solid 2px rgb(157, 157, 157) !important;
}

.cal-week-view .cal-day-headers .cal-header:nth-child(6) {
  margin-left: 1px;
}
.cal-week-view .cal-day-headers .cal-header:nth-child(7) {
  margin-left: -1px;
}

.cal-day-view .cal-current-time-marker {
  margin-left: 0 !important;
  width: 100% !important;
}

// Floating action button bottom rightover entire page.
.fab-fixed {
  position: fixed !important;
  right: 16px;
  bottom: 16px;
  margin: 0 !important;
  z-index: 10;
}

.icon {
  height: 24px;
  color: rgba(0, 0, 0, 0.33);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  margin-right: 4px;
  padding: 2px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.icon.active {
  color: var(--primary);
}

/* WebKit and Blink */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 599px) {
  .page {
    margin-bottom: 56px;
  }
}

@media print {
  .mat-sidenav-content {
    background-color: white;
  }

  .bottom-navigation {
    visibility: hidden;
  }
}

.mat-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
}
