@use 'sass:color';

$primary: #001a70;
$secondary: #fbbb21;

.de-wijkse-dienst {
  --primary: #001a70;
  --primary-contrast: #fff;
  --secondary: #fbbb21;
  --secondary-contrast: #fff;
}

$de-wijkse-dienst-palette: (
  0: #000000,
  10: color.scale($primary, $lightness: -(calc(100% / 40)) * 30),
  20: color.scale($primary, $lightness: -(calc(100% / 40)) * 20),
  25: color.scale($primary, $lightness: -(calc(100% / 40)) * 15),
  30: color.scale($primary, $lightness: -(calc(100% / 40)) * 10),
  35: color.scale($primary, $lightness: -(calc(100% / 40)) * 5),
  40: $primary,
  50: color.scale($primary, $lightness: calc(100% / 60) * 10),
  60: color.scale($primary, $lightness: calc(100% / 60) * 20),
  70: color.scale($primary, $lightness: calc(100% / 60) * 30),
  80: color.scale($primary, $lightness: calc(100% / 60) * 40),
  90: color.scale($primary, $lightness: calc(100% / 60) * 50),
  95: color.scale($primary, $lightness: calc(100% / 60) * 55),
  98: color.scale($primary, $lightness: calc(100% / 60) * 58),
  99: color.scale($primary, $lightness: calc(100% / 60) * 59),
  100: #ffffff,
  secondary: (
    0: #000000,
    10: color.scale($secondary, $lightness: -(calc(100% / 40)) * 30),
    20: color.scale($secondary, $lightness: -(calc(100% / 40)) * 20),
    25: color.scale($secondary, $lightness: -(calc(100% / 40)) * 15),
    30: color.scale($secondary, $lightness: -(calc(100% / 40)) * 10),
    35: color.scale($secondary, $lightness: -(calc(100% / 40)) * 5),
    40: $secondary,
    50: color.scale($secondary, $lightness: calc(100% / 60) * 10),
    60: color.scale($secondary, $lightness: calc(100% / 60) * 20),
    70: color.scale($secondary, $lightness: calc(100% / 60) * 30),
    80: color.scale($secondary, $lightness: calc(100% / 60) * 40),
    90: color.scale($secondary, $lightness: calc(100% / 60) * 50),
    95: color.scale($secondary, $lightness: calc(100% / 60) * 55),
    98: color.scale($secondary, $lightness: calc(100% / 60) * 58),
    99: color.scale($secondary, $lightness: calc(100% / 60) * 59),
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1c1b1f,
    20: #313034,
    25: #3c3b3f,
    30: #47464a,
    35: #535256,
    40: #5f5e62,
    50: #78767a,
    60: #929094,
    70: #adaaaf,
    80: #c8c5ca,
    90: #e5e1e6,
    95: #f3eff4,
    98: #fcf8fd,
    99: #fffbff,
    100: #ffffff,
    4: #0e0e11,
    6: #131316,
    12: #201f23,
    17: #2a292d,
    22: #353438,
    24: #39393c,
    87: #dcd9de,
    92: #eae7ec,
    94: #f0edf1,
    96: #f6f2f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #1b1b23,
    20: #303038,
    25: #3b3b43,
    30: #46464f,
    35: #52515b,
    40: #5e5d67,
    50: #777680,
    60: #918f9a,
    70: #acaab4,
    80: #c7c5d0,
    90: #e4e1ec,
    95: #f2effa,
    98: #fcf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  )
);