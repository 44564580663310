@use '@angular/material' as mat;
@use 'mixins';
@use 'sass:map';

@import 'color-palettes/default.scss';
@import 'color-palettes/heen-en-weer.scss';
@import 'color-palettes/heumen.scss';
@import 'color-palettes/plusbus.scss';
@import 'color-palettes/de-wijkse-dienst.scss';

@include mat.core();

$default-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $default-palette,
  )
));
body {
  @include mat.all-component-themes($default-theme);
}
.default {
  @include mixins.mat-component-variants($default-theme);
}

$heen-en-weer-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $heen-en-weer-palette,
  )
));
.heen-en-weer {
  @include mat.all-component-colors($heen-en-weer-theme);
  @include mixins.mat-component-variants($heen-en-weer-theme);
}

$heumen-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $heumen-palette,
  )
));
.heumen {
  @include mat.all-component-colors($heumen-theme);
  @include mixins.mat-component-variants($heumen-theme);
}

$plusbus-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $plusbus-palette,
  )
));
.plusbus {
  @include mat.all-component-colors($plusbus-theme);
  @include mixins.mat-component-variants($plusbus-theme);
}

$de-wijkse-dienst-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $de-wijkse-dienst-palette,
  )
));
.de-wijkse-dienst {
  @include mat.all-component-colors($de-wijkse-dienst-theme);
  @include mixins.mat-component-variants($de-wijkse-dienst-theme);
}